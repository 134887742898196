<template>
  <div>
    <ejs-dialog
        ref="membershipSeizurePopup"
        header="압류등록"
        :allowDragging="true"
        :showCloseIcon="true"
        width="700"
        height="400"
        :enableResize="false"
        :isModal="true"
        @close="dialogClose"
    >
      <div class="window">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-body">
              <article class="body-article">
                <section class="article-section section-01">
                  <div class="section-header">
                    <div class="header-left">
                      <ul class="lookup-condition">
                        <li class="field">
                          <div class="header-title">압류등록</div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div id="section-body" class="section-body">
                    <article class="body-article">
                      <section class="article-section">
                        <div class="section-header">
                          <div class="header-left">
                            <ul class="lookup-condition">
                              <li class="field">
                                <div class="title">회원명/회원번호</div>
                                <ul class="content">
                                  <li class="item">
                                    <input-text
                                        v-model="searchData"
                                        @keydown.enter="memberPopupSearch(searchData, 'name')"
                                    />
                                  </li>
                                </ul>
                                <ul class="content">
                                  <li class="item button">
                                    <ul class="button">
                                      <li class="search">
                                        <erp-button
                                            button-div="GET"
                                            @click.native="memberPopupSearch(searchData, 'name')"
                                        >
                                          검색
                                        </erp-button>
                                      </li>
                                    </ul>
                                  </li>
                                </ul>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div class="section-body">
                          <div class="body-data">
                            <div class="data-outer">
                              <div class="data-inner">
                                <ul class="data-content">
                                  <li class="field">
                                    <div class="title">
                                      회원명
                                    </div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item">
                                          {{ member.memberName }}
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <div class="title ">
                                      회원번호
                                    </div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item">
                                          {{ member.memberNo }}
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <div class="title required">
                                      압류설정일
                                    </div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          <div class="form">
                                            <input-date
                                                ref="confDate"
                                                name="confDate"
                                                :isBindingEmptyString="true"
                                                type="body-data"
                                                v-model="member.confDate"
                                            />
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <div class="title required">
                                      압류설정 접수일
                                    </div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          <div class="form">
                                            <input-date
                                                ref="confRceptDate"
                                                name="confRceptDate"
                                                type="body-data"
                                                v-model="member.confRceptDate"
                                            />
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <div class="title required">
                                      압류종류
                                    </div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          <div class="form">
                                            <ejs-dropdownlist
                                                ref="seizureKind"
                                                :allowFiltering="false"
                                                :fields="commonCodeFields"
                                                cssClass="body-data-dropdown"
                                                :dataSource="seizureKindList"
                                                v-model="member.seizrKind"
                                            ></ejs-dropdownlist>
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <div class="title required">
                                      압류상태
                                    </div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          <div class="form">
                                            <ejs-dropdownlist
                                                ref="seizureStatus"
                                                :allowFiltering="false"
                                                :fields="commonCodeFields"
                                                cssClass="body-data-dropdown"
                                                :dataSource="seizureStatusList"
                                                v-model="member.seizrStatus"
                                            ></ejs-dropdownlist>
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <div class="title ">
                                      사건번호
                                    </div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          <div class="form">
                                            <input-text v-model="member.incdntNo" />
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <div class="title ">
                                      해당기관
                                    </div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          <div class="form">
                                            <input-text v-model="member.crrspndInstt" />
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <div class="title ">
                                      채권자
                                    </div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          <div class="form">
                                            <input-text v-model="member.crdtr" />
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <div class="title ">
                                      금액
                                    </div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          <div class="form">
                                            <input-number
                                                ref="focusArea"
                                                v-model="member.cditAmt"
                                                displayComma
                                            />
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <div class="title ">
                                      해지접수일
                                    </div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          <div class="form">
                                            <input-date
                                                ref="trmnatRceptDate"
                                                name="trmnatRceptDate"
                                                :isBindingEmptyString="true"
                                                type="body-data"
                                                v-model="member.trmnatRceptDate"
                                            />
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <div class="title ">
                                      해지일
                                    </div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          <div class="form">
                                            <input-date
                                                ref="trmnatDate"
                                                name="trmnatDate"
                                                :isBindingEmptyString="true"
                                                type="body-data"
                                                v-model="member.trmnatDate"
                                            />
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <div class="title ">
                                      비고
                                    </div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          <div class="form">
                                            <input-text v-model="member.remarks" />
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                    </article>
                  </div>
                </section>
              </article>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="save keyColor">
              <erp-button
                  button-div="SAVE"
                  :is-shortcut-button="true"
                  @click.native="save"
              >
                저장
              </erp-button>
            </li>
            <li class="close">
              <erp-button button-div="CLOSE" @click.native="dialogClose">
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
    <member-select-popup
        ref="memberSelectPopup"
        v-if="isMemberSelectPopupOpen"
        @popupConfirmed="onPopupConfirmed"
    />
  </div>

</template>
<script>
import ErpButton from "@/components/button/ErpButton.vue";
import InputText from "@/components/common/text/InputText.vue";
import memberSelectPopup from "@/views/common/MemberSelectPopup.vue";
import InputDate from "@/components/common/datetime/InputDate.vue";
import {commonCodesGetCommonCode} from "@/utils/commonCodes";
import InputNumber from "@/components/common/InputNumber.vue";
import messagePopupDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import { validateFormRefs } from '@/utils/formUtil';
import GolfErpAPI from "@/api/v2/GolfErpAPI";

export default {
  name: "MembershipSeizurePopup.vue",
  components: {
    InputNumber,
    memberSelectPopup, ErpButton, InputText, InputDate
  },
  mixins: [messagePopupDialogMixin],
  data() {
    return {
      searchData: null,
      isMemberSelectPopupOpen: false,
      member: {
        seizrKind: null,
        seizrStatus: null,
      },
      seizureKindList: [],
      seizureStatusList: [],
      commonCodeFields: { text: "comName", value: "comCode" },
    };
  },
  computed: {
    validationRefList() {
      return {
        confDate: { required: true },
        confRceptDate: { required: true },
        seizrKind: { required: true },
        seizrStatus: { required: true },

      };
    }
  },
  async created() {
    this.seizureKindList= commonCodesGetCommonCode("SEIZR_KIND");
    this.seizureStatusList = commonCodesGetCommonCode("SEIZR_STATUS");
    this.member.seizrKind = this.seizureKindList[0].comCode;
    this.member.seizrStatus = this.seizureStatusList[0].comCode;
  },
  methods: {
    validateFormRefs,
    showPopup(member) {
      this.member = member;
    },
    async save() {
      try {
        if(!this.validateFormRefs(this.validationRefList)) { return; }
        if(!this.member.membershipId) {
          this.errorToast("membershipId가 없습니다.");
          return;
        }

        await GolfErpAPI.patchSeizure(this.member);

        this.infoToast("저장되었습니다.");
        this.dialogClose({ changed: true });
      } catch (e) {
        this.errorToast("저장에 실패하였습니다.");
      }
    },
    dialogClose({ changed }) {
      this.$emit("close", { changed });
    },
    memberPopupSearch(args, searchType) {
      this.isMemberSelectPopupOpen = true;
      const member = {
        isGiver: true, // 정회원만?
        memberNameNo: searchType === "name" ? args : "",
      };
      this.$nextTick(() => {
        this.$refs.memberSelectPopup.showPopup(member);
      });
    },
    onPopupConfirmed({ selectedRowData }) {
      this.member = {
        ...this.member,
        ...selectedRowData
      };
      this.isMemberSelectPopupOpen = false;

    }
  },
};
</script>


<style scoped>

</style>