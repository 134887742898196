<template>
  <div class="content-wrapper">
    <div class="content-lookup">
      <div class="lookup-left">
        <ul class="lookup-condition">
          <li class="field">
            <div class="title">압류일자</div>
            <ul class="content">
              <li class="item dateRange">
                <input-date-range
                    type="lookup-condition"
                    v-model="searchOptions"
                />
              </li>
            </ul>
          </li>
          <li class="field">
            <div class="title">회원명/회원번호</div>
            <ul class="content">
              <li class="item">
                <input-text
                    v-model="searchOptions.memberNameNo"
                />
              </li>
            </ul>
          </li>
        </ul>
        <div class="lookup-lookup">
          <erp-button
              button-div="GET"
              :is-shortcut-button="true"
              @click.native="onSearch"
          >
            조회
          </erp-button>
        </div>
      </div>
    </div>
    <div class="content-body">
      <article class="body-article">
        <section class="article-section section-01">
          <div class="section-header">
            <div class="header-left">
              <div class="header-title">회원권 압류</div>
              <div class="header-caption">[0건]</div>
            </div>
            <div class="header-right">
              <ul class="header-button">
                <li class="add">
                  <erp-button
                      button-div="SAVE"
                      :is-icon-custom="true"
                      @click.native="onAddSeizure"
                  >
                    추가
                  </erp-button>
                </li>
                <li class="delete">
                  <erp-button
                    button-div="DELETE"
                    :is-icon-custom="true"
                    @click.native="onDeleteSeizure"
                  >
                    삭제
                  </erp-button>
                </li>
                <li class="print">
                  <erp-button
                      button-div="FILE"
                      @click.native="onExcelButtonClicked"
                  >
                    Excel
                  </erp-button>
                </li>
              </ul>
            </div>
          </div>
          <div class="section-body">
            <div class="body-grid">
              <ejs-grid-wrapper
                  ref="grid"
                  v-bind="gridOptions"
                  @queryCellInfo="onQueryCellInfo"
                  @headerCellInfo="onHeaderCellInfo"
                  @recordClick="onRecordClick"
              />
            </div>
          </div>
        </section>
      </article>
    </div>
    <membership-seizure-popup
        ref="membershipSeizurePopup"
        v-if="isOpenMembershipSeizurePopup"
        @close="closeMembershipSeizurePopup"
    ></membership-seizure-popup>
  </div>
</template>

<script>
import commonMixin from '@/views/layout/mixin/commonMixin';
import confirmDialogMixin from '@/views/layout/mixin/messagePopupDialogMixin';
import EjsGridWrapper from "@/components/common/EjsGridWrapper";
import InputDateRange from "@/components/common/datetime/InputDateRange.vue";
import {ExcelExport, Filter, Page, Resize} from "@syncfusion/ej2-vue-grids";
import ErpButton from "@/components/button/ErpButton.vue";
import moment from "moment";
import {DATE_FORMAT_YYYY_MM_DD} from "@/utils/date";
import InputText from "@/components/common/text/InputText.vue";
import GolfErpAPI from "@/api/v2/GolfErpAPI";
import {numberWithCommas} from "@/utils/number";
import MembershipSeizurePopup from "@/views/transfer-of-name/popup/MembershipSeizurePopup.vue";
import {commonCodesGetComName} from "@/utils/commonCodes";

export default {
  name: "MembershipSeizure",
  components: {
    MembershipSeizurePopup,
    InputText,
    InputDateRange,
    EjsGridWrapper,
    ErpButton
  },
  mixins: [commonMixin, confirmDialogMixin],
  data() {
    return {
      searchOptions: {
        from: moment().startOf('month').format(DATE_FORMAT_YYYY_MM_DD),
        to: moment().format(DATE_FORMAT_YYYY_MM_DD),
        memberDiv: "",
        memberNameNo: "",
      },
      dataSource: [],
      dropDownOption: {
        memberDiv: [],
      },
      isOpenMembershipSeizurePopup: false,
    };
  },
  async created() {
    const fetchData = await GolfErpAPI.getSeizureList({
      fromDate: this.searchOptions.from,
      toDate: this.searchOptions.to,
    });
    this.dataSource = fetchData.map((item) => {
      return {
        ...item,
        seizrKindName: commonCodesGetComName("SEIZR_KIND", item.seizrKind),
        seizrStatusName: commonCodesGetComName("SEIZR_STATUS", item.seizrStatus),
      };
    });
  },
  computed: {
    gridOptions() {
      return {
        provides: [
          Filter,
          Resize,
          Page,
          ExcelExport,
        ],
        selectionSettings: {
          persistSelection: false,
          type: "Single",
          enableToggle: true,
        },
        allowEditing: false,
        allowExcelExport: true,
        allowPaging: true,
        noColumnWidth: 40,
        pageSettings: { pageSize: 50 },
        dataSource: this.dataSource,
        columns: [
          {
            field: "seizrId",
            type: "string",
            visible: false,
          },
          {
            field: "membershipId",
            type: "string",
            visible: false,
          },
          {
            field: "confDate",
            headerText: "압류일자",
            type: "string",
            width: 90,
          },
          {
            field: "memberNo",
            headerText: "회원번호",
            type: "string",
            width: 90,
          },
          {
            field: "memberName",
            headerText: "회원명",
            type: "string",
            width: 90,
          },
          {
            field: "seizrKindName",
            headerText: "압류종류",
            type: "string",
            width: 90,
          },
          {
            field: "seizrStatusName",
            headerText: "압류상태",
            type: "string",
            width: 90,
          },
          {
            field: "seizrKind",
            headerText: "압류종류코드",
            type: "string",
            width: 90,
            visible: false,
          },
          {
            field: "seizrStatus",
            headerText: "압류상태코드",
            type: "string",
            width: 90,
            visible: false,
          },
          {
            field: "incdntNo",
            headerText: "사건번호",
            type: "string",
            width: 90,
          },
          {
            field: "crrspndInstt",
            headerText: "해당기관",
            type: "string",
            width: 90,
          },
          {
            field: "crdtr",
            headerText: "채권자",
            type: "string",
            width: 90,
          },
          {
            field: "cditAmt",
            headerText: "금액",
            type: "string",
            width: 90,
            valueAccessor: (field, data) => {
              return numberWithCommas(data[field]);
            },
          },
          {
            field: "remarks",
            headerText: "비고",
            type: "string",
            width: 90,
          },
          {
            field: "trmnatDate",
            headerText: "해지일자",
            type: "string",
            width: 90,
          },
        ],
      };
    },
  },
  methods: {
    onQueryCellInfo(args) {
      const { column: { field }, cell } = args;
      if(field === 'memberNo') {
        cell.classList.add(this.$t("className.grid.clickArea"));
      }
      if(field === 'isSelect') {
        cell.classList.add(this.$t("className.grid.modifyArea"));
      }
    },
    onHeaderCellInfo(args) {
      if (args.cell.column.field === "memberNo") {
        args.node.classList.add(this.$t("className.grid.clickArea"));
      }
    },
    async onSearch() {
      this.dataSource = await GolfErpAPI.getSeizureList({
        fromDate: this.searchOptions.from,
        toDate: this.searchOptions.to,
        memberNameNo: this.searchOptions.memberNameNo,
      });
    },
    onAddSeizure() {
      this.isOpenMembershipSeizurePopup = true;
    },
    closeMembershipSeizurePopup({ changed }) {
      this.isOpenMembershipSeizurePopup = false;
      changed && this.onSearch();
    },
    onRecordClick(args) {
      const { column: { field }, rowData } = args;
      if(field === 'memberNo') {
        this.isOpenMembershipSeizurePopup = true;
        this.$nextTick(() => {
          this.$refs.membershipSeizurePopup.showPopup(rowData);
        });
      }
    },
    onExcelButtonClicked() {
      if (this.dataSource.length === 0) {
        this.errorToast("조회된 데이터가 없습니다");
        return;
      }
      this.$refs.grid.excelExport({fileName:"회원권 압류 리스트.xlsx"});
    },
    async onDeleteSeizure() {
      const [selectedRow] = this.$refs.grid.getSelectedRecords();
      if(!selectedRow.seizrId) {
        this.errorToast("선택된 행이 없습니다.");
        return;
      }

      const isConfirm = await this.confirm("선택된 행을 삭제하시겠습니까?");
      if(!isConfirm) {return;}

      try {
        await GolfErpAPI.deleteSeizure(selectedRow.seizrId);
        this.infoToast("삭제되었습니다.");
        await this.onSearch();
      } catch (e) {
        this.errorToast("삭제 중 오류가 발생했습니다.");
      }
    }
  },
};
</script>
<style scoped>
body .appContent .body-article .section-body {overflow: hidden; border: none;}
</style>